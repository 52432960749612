import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-owl-carousel2/src/owl.carousel.css";
import "./assets/scss/App.scss";
ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter basename="/metaqualt.com">
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
