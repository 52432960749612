
import './Loader.css';
import Img from "../../assets/img/brand/metaqualt.gif"
const Loader = () => (

    <div className="vp-loader">
      <img src={Img} alt="" />
    </div>

);

export default Loader;