import React from "react";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Footer from "../../components/Layout/Footer";

// Breadcrumbs Background Image
import bannerbg from "../../assets/img/banner/banner-6.webp";
import ScrollToTop from "../../components/Common/SectionTitle/ScrollTop";
import Block_Chain from "../../components/Common/Breadcumb/Block_Chain";
import BlockChain_Main from "./BlockChain_Main";

const BlockChain = () => {
  return (
    <React.Fragment>
      <HeaderStyleThree
        parentMenu="page"
        secondParentMenu="services"
        activeMenu="/services"
      />
      <div>
        <Block_Chain
          pageTitle="MetaQualt Blockchain Services"
          titleClass="page-title"
          pageName="Blockchain Services"
          breadcrumbsImg={bannerbg}
          animatedFont="lined-Font dance2"
          animatedFontsize="MetaQualt Blockchain Services"
        />

        <BlockChain_Main />
      </div>
      <ScrollToTop />
      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default BlockChain;
