import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";

import banner_Img from "../../assets/img/banner/Untitled design (17).webp";

import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";

// Service Icons
import imgMain50 from "../../assets/img/service/style2/main-img/105.webp";
import imgMain51 from "../../assets/img/service/style2/main-img/106.webp";
import imgMain52 from "../../assets/img/service/style2/main-img/107.webp";
import imgMain53 from "../../assets/img/service/style2/main-img/108.webp";
import imgMain54 from "../../assets/img/service/style2/main-img/109.webp";
import imgMainai55 from "../../assets/img/service/style2/main-img/Untitled design (18).webp";

// Service Image Icons
import experiencedai from "../../assets/img/service/style2/main-img/102.webp";
import experiencedai1 from "../../assets/img/service/style2/main-img/104.webp";
import experiencedai2 from "../../assets/img/service/style2/main-img/103.webp";

import effectImg2 from "../../assets/img/about/dotted-3.webp";
import effectImg3 from "../../assets/img/about/shape3.webp";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import { CounterSection } from "../../components/CounterSection/Data";

import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";

const BlockChain_Main = () => {
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-6 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="AI Innovation Hub"
                titleClass="title pb-25"
                title="Unlock the Power of Blockchain with MetaQualt"
                descClass="desc pb-5"
                description="Blockchain is more than just a technology; it’s a paradigm shift in trust, security, and efficiency. Since 2012, MetaQualt has been at the forefront of this revolution, crafting enterprise-grade blockchain solutions that redefine industries. From secure smart contracts to scalable decentralized applications (dApps), we enable businesses to harness blockchain’s full potential with precision, reliability, and innovation."
                secondDescClass="desc pb-16"
              />
              <ConsultNowBtn />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="text-center">
                <img className="main" src={banner_Img} alt="" />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>
      <div className="rs-process style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Guiding Innovation"
            titleClass="title pb-10"
            title="Why Choose MetaQualt for Blockchain?"
            descClass="desc"
            description="With over a decade of expertise, MetaQualt stands as a pioneer in blockchain innovation. Our approach blends deep industry knowledge, robust security protocols, and seamless integration capabilities. Whether you are launching a Web3 project, optimizing supply chain transparency, or securing financial transactions, we deliver tailor-made blockchain solutions that ensure scalability, security, and success."
            effectClass="heading-line"
          />

          <div className="rs-services style3 modify2 pt-20">
            <div className="row">
              <div className="col-lg-6  mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain50}
                  HoverImg={imgMain50}
                  Title="Enterprise Blockchain Solutions"
                  Text="Build secure and scalable private or public blockchain networks tailored to your business needs. Our solutions enhance transparency, security, and operational efficiency across industries."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain51}
                  HoverImg={imgMain51}
                  Title="Smart Contract Development"
                  Text="Automate trust and transactions with self-executing smart contracts on Ethereum, Solana, Hyperledger, and more. Our expert developers ensure precision, security, and cost efficiency.
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain52}
                  HoverImg={imgMain52}
                  Title="Decentralized Applications (dApps)"
                  Text="From DeFi platforms to NFT marketplaces, we create intuitive and high-performance dApps that drive engagement and revenue while ensuring seamless user experiences.
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain53}
                  HoverImg={imgMain53}
                  Title="Blockchain Consulting & Strategy
    "
                  Text="Navigate the complexities of blockchain with our expert advisory services. From tokenomics design to security audits, we help you develop a winning strategy tailored to your vision."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain54}
                  HoverImg={imgMain54}
                  Title="Tokenization & Digital Assets"
                  Text="Transform assets into secure and tradable digital tokens with our advanced tokenization solutions. Whether it's real estate, art, or financial instruments, we bring liquidity to your assets.
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMainai55}
                  HoverImg={imgMainai55}
                  Title="Experience in Gaming & NFTs with Governance & Identity"
                  Text="Immersive digital asset ownership and monetization.Trustworthy digital identity solutions."
                  btnClass="d-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Process"
            titleClass="title title"
            title="Industries We Serve
                            "
            effectClass="heading-line"
            descClass="desc w-80"
            description="From finance to healthcare, MetaQualt’s blockchain solutions are reshaping industries worldwide. Our expertise spans:"
          />
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai}
                Title="Finance & DeFi"
                Text="Secure, transparent, and automated financial ecosystems."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai1}
                Title="Supply Chain & Logistics"
                Text="Real-time traceability and fraud prevention.
"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={experiencedai2}
                Title="Healthcare"
                Text="Secure patient records and seamless interoperability."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="accordions pb-50">
        <div className="container">
          <FAQHeader data={faqDescriptions["Blockchain"]} />

          <AccordionCards data={accordions.Blockchain} />
        </div>
      </div>

      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
        ctaTitleClass="epx-title"
        ctaTitle="Future-Proof Your Business with MetaQualt Blockchain"
        ctaTextClass="exp-text"
        ctaText="The blockchain revolution is happening now, and MetaQualt ensures you stay ahead. Whether you're a startup exploring blockchain or an enterprise ready for full-scale implementation, we have the expertise, technology, and vision to bring your ideas to life."
        ctaSubTitle="Ready to Innovate?"
        ctaText2="Get in touch with our blockchain experts today and transform the way you do business."
        ctaText3="MetaQualt – Where Innovation Meets Trust."
        cta2TextClass="exp-text mannage"
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Get In Touch"
      />
    </React.Fragment>
  );
};

export default BlockChain_Main;
