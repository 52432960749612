import React from "react";
import { Link } from "react-router-dom";

const FooterBottom = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-bottom">
      <div className="container">
        <div className="row y-middle">
          <div className="col-lg-7  text-right md-text-center md-mb-10 order-last">
            <ul className="copy-right-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-5">
            <div className="copyright md-text-center md-mb-10">
              <p>
                {currentYear} All Rights Reserved. Metaqualt Software Private
                Limited <a href="/" target="_blank"></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
