import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


const RSMobileMenu = ({ menuOpen, activeMenu, setMenuOpen }) => {
  const personlcss1 = {
    padding: "5px",
  };

  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [services, setService] = useState(false);
  const [solution, setSolution] = useState(false);
  const [blog, setBlog] = useState(false);
  const [hiredevelopers, setHiredevelopers] = useState(false);
  const [Portfolio, setPortfolio] = useState(false);

  const openMobileMenu = (menu) => {
    if (menu === "home") {
      setHome(!home);
      setAbout(false);
      setService(false);
      setBlog(false);
      setSolution(false);
      setHiredevelopers(false);
      setPortfolio(false);
    } else if (menu === "about") {
      setHome(false);
      setAbout(!about);
      setService(false);
      setBlog(false);
      setSolution(false);
      setHiredevelopers(false);
      setPortfolio(false);
    } else if (menu === "services") {
      setHome(false);
      setAbout(false);
      setService(!services);
      setBlog(false);
      setSolution(false);
      setHiredevelopers(false);
      setPortfolio(false);
    } else if (menu === "solution") {
      setHome(false);
      setAbout(false);
      setService(false);
      setBlog(false);
      setSolution(!solution);
      setHiredevelopers(false);
      setPortfolio(false);
    } else if (menu === "blog") {
      setHome(false);
      setAbout(false);
      setService(false);
      setBlog(!blog);
      setSolution(false);
      setHiredevelopers(false);
      setPortfolio(false);
    } else if (menu === "hiredevelopers") {
      setHome(false);
      setAbout(false);
      setService(false);
      setBlog(false);
      setSolution(false);
      setHiredevelopers(!hiredevelopers);
      setPortfolio(false);
    } else if (menu === "Portfolio") {
      setHome(false);
      setAbout(false);
      setService(false);
      setBlog(false);
      setSolution(false);
      setHiredevelopers(false);
      setPortfolio(!Portfolio);
    }
  };

  return (
    <div className="container relative">
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <li
              className={
                services
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                onClick={() => {
                  openMobileMenu("services");
                }}
              >
                Service
              </Link>
              <ul className={services ? "sub-menu current-menu" : "sub-menu"}>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Microsoft Tech</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/microsoft-dotnet"
                            className={
                              activeMenu === "/microSoft-dotnet"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Microsoft .NET Development
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/cloud-consulting"
                            className={
                              activeMenu === "/cloud-consulting"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Cloud Consulting
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/application-migration"
                            className={
                              activeMenu === "/application-migration"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Application Migration{" "}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/application-integration"
                            lassName={
                              activeMenu === "/hire-android-app-developer"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Application Integration
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/cloud-app-development"
                            lassName={
                              activeMenu === "/cloud-app-development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Cloud Application Development
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/saas-app-development"
                            lassName={
                              activeMenu === "/saas-app-development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            SaaS Application Development
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Resources</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/power-bi"
                            lassName={
                              activeMenu === "/power-bi" ? "active-menu" : ""
                            }
                          >
                            Power BI
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/tableau"
                            lassName={
                              activeMenu === "/tableau" ? "active-menu" : ""
                            }
                          >
                            Tableau
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/database-management"
                            lassName={
                              activeMenu === "/database-management"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Database Management
                          </NavLink>{" "}
                        </li>
                      
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>DeepTech</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/artificial-intelligence"
                            lassName={
                              activeMenu === "/artificial-intelligence"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Artificial Intelligence (AI)
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/machine-learning"
                            lassName={
                              activeMenu === "/machine-learning"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Machine Learning
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/data-science"
                            lassName={
                              activeMenu === "/data-science"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Data Science
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/blockchain"
                            lassName={
                              activeMenu === "/blockchain"
                                ? "active-menu"
                                : ""
                            }
                          >
                            BlockChain
                          </NavLink>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner">
                      <h4>Digital Transformation</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/ux-ui-design"
                            lassName={
                              activeMenu === "/ux-ui-design"
                                ? "active-menu"
                                : ""
                            }
                          >
                            UI/UX Design
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/digitle-market"
                            lassName={
                              activeMenu === "/digitle-market"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Digital Marketing
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/quality-engineering"
                            lassName={
                              activeMenu === "/quality-engineering"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Quality Engineering
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner">
                      <h4>Application Development</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/web-development"
                            lassName={
                              activeMenu === "/web-development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Web Development
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/software-development"
                            lassName={
                              activeMenu === "/software-development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Software Development
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/mobile-app-development"
                            lassName={
                              activeMenu === "/mobile-app-development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Mobile App Development
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/cms-development"
                            lassName={
                              activeMenu === "/cms-development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            CMS Development
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/crm-development"
                            lassName={
                              activeMenu === "/crm-development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            CRM Development
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/ecommerce-development"
                            lassName={
                              activeMenu === "/ecommerce-development"
                                ? "active-menu"
                                : ""
                            }
                          >
                            e-commerce Development
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ul>
            </li>
            <li
              className={
                solution
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                onClick={() => {
                  openMobileMenu("solution");
                }}
              >
                Solution
              </Link>
              <ul className={solution ? "sub-menu current-menu" : "sub-menu"}>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>By Business Scales</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/startup-solutions"
                            lassName={
                              activeMenu === "/startup-solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Startup Solutions
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/enterprise-solution"
                            lassName={
                              activeMenu === "/enterprise-solution"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Enterprise Solution
                          </NavLink>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Custom Solutions</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/devops-solutions"
                            lassName={
                              activeMenu === "/Devops-Solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            DevOps Solutions
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/cloud-solutions"
                            lassName={
                              activeMenu === "/Cloud-Solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Cloud Solutions
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/on-demand-business-solutions"
                            lassName={
                              activeMenu === "/On-Demand-Business-Solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            On-demand Business Solutions
                          </NavLink>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Industry Expertise</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/business-app-solution"
                            lassName={
                              activeMenu === "/business-app-solution"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Retail/Business{" "}
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/fitness-wellness-app-solutions"
                            lassName={
                              activeMenu === "/fitness-wellness-app-solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Fitness App{" "}
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/fintech-app-solution"
                            lassName={
                              activeMenu === "/fintech-app-solution"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Fintech-App{" "}
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/travel-app-solutions"
                            lassName={
                              activeMenu === "/travel-app-solutions"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Travel Tourism
                          </NavLink>{" "}
                        </li>

                        <li>
                          <NavLink
                            to="/real-estate-app-solution"
                            lassName={
                              activeMenu === "/real-estate-app-solution"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Real Estate{" "}
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/healthcare-app-solution"
                            lassName={
                              activeMenu === "/healthcare-app-solution"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Healthcare
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink to="./ecommerce-solutions">
                            E-Commerce App
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner">
                      <h4>Salesforce Development</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/salesforce-consulting-services"
                            lassName={
                              activeMenu === "/Salesforce-Consulting-Services"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Salesforce Consulting
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/salesforce-implementation"
                            lassName={
                              activeMenu === "/Salesforce-Implementation"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Salesforce Implementation{" "}
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/salesforce-lightning-services"
                            lassName={
                              activeMenu === "/Salesforce-Lightning-Services"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Salesforce Lightning{" "}
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/salesforce-exchange"
                            lassName={
                              activeMenu === "/Salesforce-Exchange"
                                ? "active-menu"
                                : ""
                            }
                          >
                            SalesForce App Exchange
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/salescloud-consulting"
                            lassName={
                              activeMenu === "/SalesCloud-Consulting"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Sales Cloud Consulting{" "}
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ul>
            </li>
            <li
              className={
                hiredevelopers
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                onClick={() => {
                  openMobileMenu("hiredevelopers");
                }}
              >
                Hire offshore developers
              </Link>
              <ul
                className={
                  hiredevelopers ? "sub-menu current-menu" : "sub-menu"
                }
              >
                <div className="row">
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Mobile App Developers</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/hire-flutter-developers"
                            lassName={
                              activeMenu === "/hire-flutter-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Flutter Developers
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/hire-react-native-developers"
                            lassName={
                              activeMenu === "/hire-react-native-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            React Native Developers
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/hire-iphone-app-developer"
                            lassName={
                              activeMenu === "/hire-ios-app-developer"
                                ? "active-menu"
                                : ""
                            }
                          >
                            iOS Developers
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/hire-android-app-developer"
                            lassName={
                              activeMenu === "/hire-android-app-developer"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Android Developers
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Full Stack Developers</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/hire-mean-stack-developers"
                            lassName={
                              activeMenu === "/hire-mean-stack-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            MeanStack Developers
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/hire-php-developers"
                            lassName={
                              activeMenu === "/hire-php-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            PHP Developers
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/hire-python-developers"
                            lassName={
                              activeMenu === "/hire-python-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Python Developers
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/hire-nodejs-developers"
                            lassName={
                              activeMenu === "/hire-nodejs-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Node.js Developers
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/hire-angular-developer"
                            lassName={
                              activeMenu === "/hire-angular-developer"
                                ? "active-menu"
                                : ""
                            }
                          >
                            AngularJs Developers
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/hire-vuejs-developer"
                            lassName={
                              activeMenu === "/hire-vuejs-developer"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Vue Developers
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/hire-reactjs-developers"
                            lassName={
                              activeMenu === "/hire-reactjs-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            ReactJs Developers
                          </NavLink>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner mega_menu_set">
                      <h4>Functional Resources</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/business-analyst"
                            lassName={
                              activeMenu === "/Business-Aanalyst"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Business Analyst
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/project-managers"
                            lassName={
                              activeMenu === "/Project-Managers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Project Managers
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/software-testers"
                            lassName={
                              activeMenu === "/Software-Testers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Software Testers
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/hire-designers"
                            lassName={
                              activeMenu === "/Hire-Designers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            {" "}
                            Hire Designers
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="main_menu_inner">
                      <h4>Experts In</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/hire-ui-ux-developers"
                            lassName={
                              activeMenu === "/hire-ux-ui-developers"
                                ? "active-menu"
                                : ""
                            }
                          >
                            UI/UX Developer
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ul>
            </li>
            <li
              className={
                blog
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                onClick={() => {
                  openMobileMenu("blog");
                }}
              >
                Company
              </Link>
              <ul className={blog ? "sub-menu current-menu" : "sub-menu"}>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="main_menu_inner mega_menu">
                      <h4>About Metaqualt</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/about"
                            lassName={
                              activeMenu === "/about" ? "active-menu" : ""
                            }
                          >
                            About us
                          </NavLink>{" "}
                        </li>

                        <li>
                          <NavLink
                            to="/client-testimonials"
                            lassName={
                              activeMenu === "/Client-Testimonials"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Client Testimonials
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink
                            to="/contact"
                            lassName={
                              activeMenu === "/Client-Testimonials"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Contact Us
                          </NavLink>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="main_menu_inner mega_menu">
                      <h4>Careers</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/current-openings"
                            lassName={
                              activeMenu === "/Current-Openings"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Current Openings
                          </NavLink>{" "}
                        </li>

                        <li>
                          <NavLink
                            to="/life-at-metaqualt"
                            lassName={
                              activeMenu === "/Life-At-Metaqualt"
                                ? "active-menu"
                                : ""
                            }
                          >
                            Life At Metaqualt
                          </NavLink>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="main_menu_inner">
                      <h4>Resources</h4>
                      <ul>
                        <li>
                          <NavLink
                            to="/blog"
                            lassName={
                              activeMenu === "/blog" ? "active-menu" : ""
                            }
                          >
                            Blog
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/white-paper"
                            lassName={
                              activeMenu === "/white-paper" ? "active-menu" : ""
                            }
                          >
                            White paper
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ul>
            </li>

            <li>
              <NavLink
                to="/portfolio"
                className={activeMenu === "/Portfolio" ? "active-menu" : ""}
              >
                Portfolio
              </NavLink>
            </li>

            <li style={personlcss1}>
              <Link className="rs-get-in ml-18" to="/contact">
                Get In Touch
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
