import React, { Suspense } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
} from "react-router-dom";

//Custom Components

import LoadTop from "../components/Common/SectionTitle/ScrollTop/LoadTop";

import Loader from "../components/Loader/Loader";
import BlockChain from "../pages/Blockchain/BlockChain";

const Home = React.lazy(() => import("../pages/home"));
const WebDevelopment = React.lazy(() => import("../pages/web-development"));
const AngularJs = React.lazy(() => import("../pages/angularJs-development"));
const WordpressDevelopment = React.lazy(() =>
  import("../pages/cms-development")
);
const ReactJS = React.lazy(() => import("../pages/reactJs-development"));
const MobileAppDevelopment = React.lazy(() =>
  import("../pages/mobile-app-development")
);
const AndroidAppDevelopment = React.lazy(() =>
  import("../pages/android-app-development")
);
const IosAppDevelopment = React.lazy(() =>
  import("../pages/ios-app-development")
);
const webDesign = React.lazy(() => import("../pages/ux-ui-design"));
const Machin_learning_sec = React.lazy(() =>
  import("../pages/machine_learning")
);
const QualityEngineering = React.lazy(() =>
  import("../pages/quality_engineering")
);
const ArtificialIntelligenceSec = React.lazy(() =>
  import("../pages/artificial-intelligence")
);
const eCommerce = React.lazy(() => import("../pages/eCommerce-development"));

const EcommerceSolutionsMain = React.lazy(() =>
  import("../pages/ecommerce-solutions")
);
const DigitalMarketSec = React.lazy(() => import("../pages/digitl-market"));
const DataScienceSec = React.lazy(() => import("../pages/data-science"));
const CRM_Development_sec = React.lazy(() =>
  import("../pages/crm_development")
);
const SoftwareDevelopmentsec = React.lazy(() =>
  import("../pages/softwaredevelopment")
);
const Microsoft_dev_sec = React.lazy(() => import("../pages/microsoftNETdev"));
const Cloud_consulting_sec = React.lazy(() =>
  import("../pages/cloudconsulting")
);
const Application_migration_sec = React.lazy(() =>
  import("../pages/applicationmigration")
);
const Salesforceconsulting_solution = React.lazy(() =>
  import("../pages/salesforceConsultingServices")
);
const Salesforce_Lightning = React.lazy(() =>
  import("../pages/SalesforceLightningServices")
);
const Salesforce_implementation = React.lazy(() =>
  import("../pages/salesforceimplementation")
);
const Salesforce_cloud = React.lazy(() =>
  import("../pages/salescloudconsulting")
);

const HireWebDevelopers = React.lazy(() =>
  import("../pages/hire-web-developers")
);
const Faq = React.lazy(() => import("../pages/faq"));
const ErrorPage = React.lazy(() => import("../pages/ErrorPage"));
const Salesforce_AppExchange = React.lazy(() =>
  import("../pages/salesforceAppExchange")
);
const SaasAppDevelopment = React.lazy(() => import("../pages/SaasDevelopment"));
const Power_BI_sec = React.lazy(() => import("../pages/Power BI"));
const application_integration = React.lazy(() =>
  import("../pages/Application_Integration")
);
const Cloud_App_Devlopment = React.lazy(() =>
  import("../pages/Cloud_App_Development")
);
const Tableau_M = React.lazy(() => import("../pages/Tableau"));
const Database_Management_Main = React.lazy(() =>
  import("../pages/Database-Management")
);
const White_Paper = React.lazy(() => import("../pages/white-paper"));
const WhitePaperDetail = React.lazy(() =>
  import("../pages/white-paper/WhitePaperDetail")
);
const DetailPage = React.lazy(() =>
  import("../pages/life_metaqualt/DetailPage")
);

// Solution Pages

const StartupSolution_sec = React.lazy(() =>
  import("../pages/startup-solutions")
);
const Enterprise_Solution_sec = React.lazy(() =>
  import("../pages/enterprise-solution")
);
const Devops_SolutionsSec = React.lazy(() =>
  import("../pages/devops_Solutions")
);
const CloudsolutionsmainSec = React.lazy(() =>
  import("../pages/Cloud_SolutionS")
);
const OndemandBusinessSolutions = React.lazy(() =>
  import("../pages/OndemandBusinessSolutions")
);
const FitnessAppMain = React.lazy(() =>
  import("../pages/fitness-wellness-app-solutions")
);
const BusinessAppSolution = React.lazy(() =>
  import("../pages/business-app-solution")
);
const TravelAppMain = React.lazy(() => import("../pages/travel-app-solutions"));
const RealEstateAppMain = React.lazy(() =>
  import("../pages/real-estate-app-solution")
);
const HealthcareAppMain = React.lazy(() =>
  import("../pages/healthcare-app-solution")
);

// Hire Offshare Developers

const HirePHPDevelopersMain = React.lazy(() =>
  import("../pages/hire-php-developers")
);

const HireReactNativeDevelopersMain = React.lazy(() =>
  import("../pages/hire-react-native-developers")
);
const HireMeanStackDevelopersMain = React.lazy(() =>
  import("../pages/hire-mean-stack-developers")
);
const HireFullStackDevelopersMain = React.lazy(() =>
  import("../pages/hire-full-stack-developers")
);
const HireFlutterDevelopersMain = React.lazy(() =>
  import("../pages/hire-flutter-developers")
);
const HirePythonDevelopersMain = React.lazy(() =>
  import("../pages/hire-python-developers")
);
const HireLaravelDevelopersPage = React.lazy(() =>
  import("../pages/hire-laravel-developers")
);
const HireNodeJsDevelopersPage = React.lazy(() =>
  import("../pages/hire-nodejs-developers")
);
const HireReactJsDevelopersPage = React.lazy(() =>
  import("../pages/hire-reactjs-developers")
);
const HireAngularJsDevelopersPage = React.lazy(() =>
  import("../pages/hire-angularjs-developer")
);
const HireiOSAppDevelopers = React.lazy(() =>
  import("../pages/hire-ios-app-developer")
);

const HireVueJsDevelopersPage = React.lazy(() =>
  import("../pages/hire-vuejs-developer")
);

const HireAndroidAppDevelopers = React.lazy(() =>
  import("../pages/hire-android-app-developer")
);

const BusinessAnalystSec = React.lazy(() =>
  import("../pages/business_analyst")
);

const Projectmanagerssec = React.lazy(() =>
  import("../pages/project_managers")
);

const Softwaretesterssec = React.lazy(() =>
  import("../pages/software-testers")
);

const HiredesignersMainec = React.lazy(() => import("../pages/Hire-designers"));

const HireWebDesignerDevelopers = React.lazy(() =>
  import("../pages/hire-ux-ui-developers")
);

// Company

const About = React.lazy(() => import("../pages/about"));
const FintechAppMain = React.lazy(() =>
  import("../pages/fintech-app-solution")
);

const ClientTestimonialsSec = React.lazy(() =>
  import("../pages/Client-Testimonials")
);

const CurrentopeningsSec = React.lazy(() =>
  import("../pages/Current_openings/Currentopenings")
);

const LifeAtMetaqualtSection = React.lazy(() =>
  import("../pages/life_metaqualt")
);

const Contact = React.lazy(() => import("../pages/contact"));

const Blog = React.lazy(() => import("../pages/blog"));

const BlogDetail = React.lazy(() => import("../pages/blog/BlogDetail"));

const Portfolio = React.lazy(() => import("../pages/Portfolio"));

const JSDevelopment = React.lazy(() =>
  import("../pages/javaScripts-development/index")
);

const App = () => {
  return (
    <div className="App">
      <Router>
        <LoadTop />

        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />

            <Route path="/angularjs-development" exact component={AngularJs} />
            <Route
              path="/hire-designers"
              exact
              component={HiredesignersMainec}
            />
            <Route
              path="/mobile-app-development"
              exact
              component={MobileAppDevelopment}
            />
            <Route
              path="/android-app-development"
              exact
              component={AndroidAppDevelopment}
            />
            <Route
              path="/project-managers"
              exact
              component={Projectmanagerssec}
            />
            <Route
              path="/ios-app-development"
              exact
              component={IosAppDevelopment}
            />

            <Route
              path="/business-analyst"
              exact
              component={BusinessAnalystSec}
            />
            <Route path="/reactJs-development" exact component={ReactJS} />

            <Route path="/ux-ui-Design" exact component={webDesign} />
            <Route path="/digitle-market" exact component={DigitalMarketSec} />
            <Route
              path="/quality-engineering"
              exact
              component={QualityEngineering}
            />
            <Route
              path="/artificial-intelligence"
              exact
              component={ArtificialIntelligenceSec}
            />
            <Route
              path="/microsoft-dotnet"
              exact
              component={Microsoft_dev_sec}
            />
            <Route
              path="/cloud-app-development"
              exact
              component={Cloud_App_Devlopment}
            />
            <Route
              path="/saas-app-development"
              exact
              component={SaasAppDevelopment}
            />
            <Route path="/power-bi" exact component={Power_BI_sec} />
            <Route path="/blockchain" exact component={BlockChain} />
            <Route
              path="/application-migration"
              exact
              component={Application_migration_sec}
            />
            <Route
              path="/application-integration"
              exact
              component={application_integration}
            />
            <Route path="/tableau" exact component={Tableau_M} />

            <Route
              path="/database-management"
              exact
              component={Database_Management_Main}
            />

            <Route
              path="/cloud-consulting"
              exact
              component={Cloud_consulting_sec}
            />
            <Route
              path="/machine-learning"
              exact
              component={Machin_learning_sec}
            />
            <Route path="/data-science" exact component={DataScienceSec} />
            <Route
              path="/crm-development"
              exact
              component={CRM_Development_sec}
            />
            <Route
              path="/software-development"
              exact
              component={SoftwareDevelopmentsec}
            />
            <Route
              path="/salesforce-consulting-services"
              exact
              component={Salesforceconsulting_solution}
            />
            <Route
              path="/salesforce-lightning-services"
              exact
              component={Salesforce_Lightning}
            />

            <Route
              path="/salesforce-exchange"
              exact
              component={Salesforce_AppExchange}
            />

            <Route
              path="/startup-solutions"
              exact
              component={StartupSolution_sec}
            />
            <Route
              path="/enterprise-solution"
              exact
              component={Enterprise_Solution_sec}
            />
            <Route
              path="/devops-solutions"
              exact
              component={Devops_SolutionsSec}
            />
            <Route
              path="/cloud-solutions"
              exact
              component={CloudsolutionsmainSec}
            />
            <Route
              path="/on-demand-business-solutions"
              exact
              component={OndemandBusinessSolutions}
            />
            <Route
              path="/software-testers"
              exact
              component={Softwaretesterssec}
            />

            <Route
              path="/salesforce-implementation"
              exact
              component={Salesforce_implementation}
            />

            <Route
              path="/client-testimonials"
              exact
              component={ClientTestimonialsSec}
            />
            <Route
              path="/life-at-metaqualt"
              exact
              component={LifeAtMetaqualtSection}
            />

            <Route
              path="/current-openings"
              exact
              component={CurrentopeningsSec}
            />

            <Route
              path="/life-metaqualt/life-metaqualt-details/:cardId"
              component={DetailPage}
            />

            <Route path="/web-development" exact component={WebDevelopment} />

            <Route
              path="/cms-development"
              exact
              component={WordpressDevelopment}
            />

            <Route path="/ecommerce-development" exact component={eCommerce} />

            <Route
              path="/business-app-solution"
              exact
              component={BusinessAppSolution}
            />
            <Route
              path="/fitness-wellness-app-solutions"
              exact
              component={FitnessAppMain}
            />
            <Route
              path="/travel-app-solutions"
              exact
              component={TravelAppMain}
            />
            <Route
              path="/real-estate-app-solution"
              exact
              component={RealEstateAppMain}
            />
            <Route
              path="/healthcare-app-solution"
              exact
              component={HealthcareAppMain}
            />

            <Route
              path="/hire-php-developers"
              exact
              component={HirePHPDevelopersMain}
            />

            <Route
              path="/hire-react-native-developers"
              exact
              component={HireReactNativeDevelopersMain}
            />

            <Route
              path="/hire-mean-stack-developers"
              exact
              component={HireMeanStackDevelopersMain}
            />

            <Route
              path="/hire-full-stack-developers"
              exact
              component={HireFullStackDevelopersMain}
            />

            <Route
              path="/hire-flutter-developers"
              exact
              component={HireFlutterDevelopersMain}
            />

            <Route
              path="/hire-python-developers"
              exact
              component={HirePythonDevelopersMain}
            />

            <Route
              path="/hire-laravel-developers"
              exact
              component={HireLaravelDevelopersPage}
            />
            <Route
              path="/hire-nodejs-developers"
              exact
              component={HireNodeJsDevelopersPage}
            />
            <Route
              path="/hire-reactjs-developers"
              exact
              component={HireReactJsDevelopersPage}
            />
            <Route
              path="/hire-vuejs-developer"
              exact
              component={HireVueJsDevelopersPage}
            />
            <Route
              path="/hire-android-app-developer"
              exact
              component={HireAndroidAppDevelopers}
            />
            <Route
              path="/hire-android-app-developer"
              exact
              component={HireAndroidAppDevelopers}
            />
            <Route
              path="/hire-iphone-app-developer"
              exact
              component={HireiOSAppDevelopers}
            />
            <Route
              path="/hire-angular-developer"
              exact
              component={HireAngularJsDevelopersPage}
            />

            <Route
              path="/hire-ui-ux-developers"
              exact
              component={HireWebDesignerDevelopers}
            />

            <Route
              path="/salescloud-consulting"
              exact
              component={Salesforce_cloud}
            />

            <Route
              path="/hire-web-developers"
              exact
              component={HireWebDevelopers}
            />

            <Route path="/portfolio" exact component={Portfolio} />
            <Route path="/blog" exact component={Blog} />
            <Route path="/blog/:slug" component={BlogDetail} />

            {/* <Route path="/" component={Current-openings} /> */}
            <Route path="/faq" component={Faq} />
            <Route path="/contact" component={Contact} />
            <Route path="/white-paper" exact component={White_Paper} />
            <Route
              path="/white-paper/:slug"
              component={WhitePaperDetail}
            />

            <Route path="/javascript-development" component={JSDevelopment} />
            <Route
              path="/fintech-app-solution"
              exact
              component={FintechAppMain}
            />
            <Route
              path="/ecommerce-solutions"
              exact
              component={EcommerceSolutionsMain}
            />

            {/* Catch-all route for undefined paths */}
            <Route component={ErrorPage} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
